import { Dropdown, DropdownProps } from 'primereact/dropdown';

import LoadingSpinner from 'components/Misc/LoadingSpinner';

import './style.css';

interface ICustomDropdown extends DropdownProps {
    label?: string;
    labelStyle?: React.CSSProperties;
    spinnerStyle?: React.CSSProperties;
    loading?: boolean;
    error?: string;
}

const CustomDropdown: React.FC<ICustomDropdown> = ({
    label,
    name,
    labelStyle,
    spinnerStyle,
    loading = false,
    error,
    ...rest
}) => {
    return (
        <div className='custom-dropdown'>
            <span>
                <label
                    htmlFor={name}
                    style={{
                        marginRight: '8px',
                        display: label ? 'inherit' : 'none',
                        ...labelStyle,
                    }}
                >
                    {label}
                </label>
                <div className='wrap-content'>
                    <Dropdown disabled={loading} {...rest} />
                    {loading && (
                        <LoadingSpinner
                            style={{
                                marginLeft: '8px',
                                ...spinnerStyle,
                            }}
                            size={22}
                            strokeWidth='6'
                        />
                    )}
                </div>
                {error && (
                    <small
                        id={`${name}-help`}
                        className='p-error block input-text-error'
                    >
                        {error}
                    </small>
                )}
            </span>
        </div>
    );
};

export default CustomDropdown;
