import { InputText, InputTextProps } from 'primereact/inputtext';

import './style.css';

interface ICustomInputText extends InputTextProps {
    label?: string;
    labelStyle?: React.CSSProperties;
    error?: string | null;
}

const CustomInputText: React.FC<ICustomInputText> = ({
    label,
    name,
    labelStyle,
    error,
    ...rest
}) => {
    return (
        <div className='custom-input-text'>
            <span>
                <label
                    htmlFor={name}
                    style={{
                        marginRight: '8px',
                        display : label ? 'inherit' : 'none',
                        ...labelStyle,
                    }}
                >
                    {label}
                </label>
                <InputText {...rest} />
                {error && (
                    <small
                        id={`${name}-help`}
                        className='p-error block input-text-error'
                    >
                        {error}
                    </small>
                )}
            </span>
        </div>
    );
};

export default CustomInputText;
