import {
    DataTableSortMeta,
    DataTableFilterMetaData
} from 'primereact/datatable';
import api from 'services/api';
import { getDateISO, getDateRange } from 'utils/dateUtils';
import { useQuery } from 'react-query';

interface IFetchData {
    url: string | undefined;
    accessToken?: string;
    query?: {
        limit?: number;
        offset?: number;
    };
    sort?: {
        field: string;
        order: DataTableSortMeta['order'];
    };
    params?: {
        [key: string]: string;
    };
    filters?: IFetchFilters;
}

export interface IFetchSort {
    field: string;
    order: DataTableSortMeta['order'];
}

export interface IFetchFilters {
    [key: string]: {
        value: unknown;
        filterType: DataTableFilterMetaData['matchMode'];
    };
}

export const applyApiFilters = (filters: IFetchFilters) => {
    const apiFilters: { [key: string]: string } = {};
    Object.entries(filters).forEach((item) => {
        switch (item[1].filterType) {
            case 'startsWith':
                apiFilters[item[0] + '|regex'] = '^' + item[1].value;
                break;
            case 'contains':
                apiFilters[item[0] + '|regex'] = `${item[1].value}`;
                break;
            case 'notContains':
                apiFilters[item[0] + '|regex'] = `^(?!.*${item[1].value}).*$`;
                break;
            case 'endsWith':
                apiFilters[item[0] + '|regex'] = item[1].value + '$';
                break;
            case 'equals':
                apiFilters[item[0]] = `${item[1].value}`;
                break;
            case 'notEquals':
                apiFilters[item[0] + '|ne'] = `${item[1].value}`;
                break;
            case 'lt':
                apiFilters[item[0] + '|lt'] = `${item[1].value}`;
                break;
            case 'lte':
                apiFilters[item[0] + '|lte'] = `${item[1].value}`;
                break;
            case 'gt':
                apiFilters[item[0] + '|gt'] = `${item[1].value}`;
                break;
            case 'gte':
                apiFilters[item[0] + '|gte'] = `${item[1].value}`;
                break;
            case 'dateIs': {
                const date = item[1].value as Date;
                apiFilters[item[0] + '|range'] = getDateRange(date);
                break;
            }
            case 'dateIsNot': {
                const date = item[1].value as Date;
                apiFilters[item[0] + '|lt'] = `${getDateISO(date)}`;
                apiFilters[item[0] + '|gt'] = `${getDateISO(
                    date
                )}T23:59:59.999999`;
                break;
            }
            case 'dateBefore': {
                const date = item[1].value as Date;
                apiFilters[item[0] + '|lt'] = `${getDateISO(date)}`;
                break;
            }
            case 'dateAfter': {
                const date = item[1].value as Date;
                apiFilters[item[0] + '|gt'] = `${getDateISO(
                    date
                )}T23:59:59.999999`;
                break;
            }
        }
    });
    return apiFilters;
};

const useFetchData = <T,>({
    url,
    accessToken,
    query,
    sort,
    filters,
    params,
}: IFetchData) => {
    const {
        data,
        error,
        status,
        refetch: fetchData,
    } = useQuery<T>(
        [url, params, query, sort, filters],
        async ({ signal }) => {
            if (!url) return;
            const limit = query?.limit ? query.limit : 5;
            const offset = query?.offset ? query.offset : 0;
            const ordenation = sort?.order === 1 ? '' : '-';
            const field = sort?.field;
            const apiFilters: { [key: string]: string } = filters
                ? applyApiFilters(filters)
                : {};

            const { data: response } = await api.get(url, {
                signal,
                headers: {
                    'access-token': accessToken ? accessToken : '',
                },
                params: {
                    limit,
                    offset,
                    order: sort && sort.field ? ordenation + field : undefined,
                    ...apiFilters,
                    ...params,
                },
            });
            return response;
        },
        {
            enabled: false,
        }
    );

    return { data, status, error, fetchData };
};

export default useFetchData;
