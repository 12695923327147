import { ProgressSpinner, ProgressSpinnerProps } from 'primereact/progressspinner';
import React from 'react';

import './style.css';

interface ILoadingSpinner extends ProgressSpinnerProps {
    size ?: number;
}

const LoadingSpinner : React.FC<ILoadingSpinner> = ({ size = 30, strokeWidth='4', style, ...rest }) => {
    return (
        <ProgressSpinner
            style={{ width: `${size}px`, height: `${size}px`, margin: '0 0', ...style }}
            className='custom-spinner'
            strokeWidth={strokeWidth}
            {...rest}
        />
    );
};

export default LoadingSpinner;
