import { RedirectableLogo } from 'components/Misc/Logo';
import './style.css';

const Navbar: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <section className='navbar'>
            <div className='navbar-content'>
                <RedirectableLogo />
                {children}
            </div>
        </section>
    );
};

export default Navbar;
