import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useSearchParams } from 'react-router-dom';

import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import {
    DataTable,
    DataTableFilterMeta,
    DataTableFilterEvent,
} from 'primereact/datatable';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import api from 'services/api';

import { applyApiFilters, IFetchFilters } from 'hooks/useFetchData';

import OidBadge from 'components/Misc/OidBadge';
import TheaterPlayer from './components/TheaterPlayer';
import CustomInputText from 'components/Form/CustomInputText';
import NoTotalPaginator from 'components/Form/NoTotalPaginator';

import './style.css';
import { Ether, FetchStatus } from 'types';
import CustomInputNumber from 'components/Form/CustomInputNumber';

export interface IVideoInfo {
    _id: string;
    status: string;
    video_file_oid: string;
    related_with: string;
    related_oid: string;
    related_name: string;
    related_slug: string;
    related_type: string;
    related_info_season?: string | null;
    related_info_episode?: string | null;
    features_file: {
        is_local: boolean;
        md5: string;
        compression: string;
        remote_path: string;
        s3_manager_name: string;
    } | null;
    features_error: string | null;
    features_processed_at: string;
}

const getVideoInfo = (
    title: string,
    season?: string | null,
    episode?: string | null
) => {
    return `${title}${season ? ' - Season ' + season : ''}${
        episode ? ' - Episode ' + episode : ''
    }`;
};

type Filters = {
    titleSlug: string;
    season: number | null;
    episode: number | null;
};

const ValidateVideos = () => {
    const primeToastRef = useRef<Toast>(null);

    const videoIdUri = window.API_URL;
    const titlesDbUri = window.EXTERNAL_URI_TITLEDB;

    const [searchParams] = useSearchParams();

    const paramRelatedSlug = searchParams.get('related_slug');
    const paramRelatedSeason = searchParams.get('related_info_season');
    const paramRelatedEpisode = searchParams.get('related_info_episode');

    const [filters, setFilters] = useState<Filters>({
        titleSlug: paramRelatedSlug ?? '',
        season:
            paramRelatedSeason === 'null'
                ? 0
                : paramRelatedSeason != null
                ? Number(paramRelatedSeason)
                : null,
        episode:
            paramRelatedEpisode === 'null'
                ? 0
                : paramRelatedEpisode != null
                ? Number(paramRelatedEpisode)
                : null,
    });
    const [appliedFilters, setAppliedFilters] =
        useState<Partial<Filters>>(filters);

    const [filterFields, setFilterFields] = useState<DataTableFilterMeta>({
        related_name: {
            matchMode: 'equals',
            value: null,
        },
    });

    const getVideoTitleFromOid = async (titleOid: string) => {
        const result = await api.get<Ether.IApi<Ether.TitleDB.ITitle>>(
            `${titlesDbUri}/list-title`,
            {
                params: {
                    _id: titleOid,
                },
            }
        );
        const title = {
            name: result.data.payload[0]?.name,
            type: result.data.payload[0]?.type,
            slug: result.data.payload[0]?.slug,
        };
        return title;
    };

    const baseInsertSample = async (accepted: boolean, videoId: string) => {
        try {
            await api.post<{ success: boolean; error?: string }>(
                `${videoIdUri}/insert-sample`,
                {
                    accepted,
                    video_oid: videoId,
                }
            );
            primeToastRef.current?.show({
                severity: 'success',
                summary: accepted ? 'Match accepted' : 'Match rejected',
                detail: accepted
                    ? 'Match accepted and video saved as sample. Videos from same category marked for review.'
                    : 'Match rejected and video ignored.',
                life: accepted ? 6000 : 3000,
            });
            setIsVideoPlayerVisible(false);
            setSelectedVideo(null);
            startFetch();
        } catch (err) {
            console.error(err);
            primeToastRef.current?.show({
                severity: 'error',
                summary: 'Unexpected API error',
                detail: `${(err as Error).toString()}`,
                life: 6000,
            });
        }
    };

    const saveVideoAsSample = async (video: IVideoInfo) => {
        await baseInsertSample(true, video._id);
    };

    const rejectAndIgnoreVideo = async (video: IVideoInfo) => {
        await baseInsertSample(false, video._id);
    };

    const [videoIdDataList, setVideoIdDataList] = useState<IVideoInfo[]>([]);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [editRelatedInfoVisible, setEditRelatedInfoVisible] = useState(false);
    const [isVideoPlayerVisible, setIsVideoPlayerVisible] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState<{
        videoIdData: IVideoInfo;
        titlesDbData: {
            title: string;
            season?: string | null;
            episode?: string | null;
        };
    } | null>(null);

    const [allRequestStatus, setAllRequestStatus] =
        useState<FetchStatus>('loading');

    const onSampleConfirm = (videoData: IVideoInfo) => {
        confirmDialog({
            style: {
                maxWidth: '60vw',
            },
            header: 'Accept match',
            message: `Are you sure you want to save this video (${getVideoInfo(
                videoData.related_name,
                videoData.related_info_season,
                videoData.related_info_episode
            )}) (${
                videoData._id
            }) as a sample? Videos from same title, season and episode will be marked for review and not appear on this page.`,
            accept: () => saveVideoAsSample(videoData),
        });
    };

    const onVideoReject = (videoData: IVideoInfo) => {
        confirmDialog({
            style: {
                maxWidth: '60vw',
            },
            header: 'Reject match',
            message: `Are you sure you want to ignore this video (${getVideoInfo(
                videoData.related_name,
                videoData.related_info_season,
                videoData.related_info_episode
            )}) (${
                videoData._id
            })? It will be ignored and not appear on this list anymore.`,
            accept: () => rejectAndIgnoreVideo(videoData),
        });
    };

    const getTitleIdFromSlug = async (slug: string) => {
        const result = await api.get<Ether.IApi<Ether.TitleDB.ITitle>>(
            `${titlesDbUri}/list-title`,
            {
                params: {
                    slug: slug,
                    limit: 1,
                    _fields: '_id',
                },
            }
        );
        return result.data.payload[0] ? result.data.payload[0]._id : null;
    };

    const {
        data: videosData,
        status: videosQueryStatus,
        refetch: refetchVideos,
    } = useQuery<Ether.IApi<Ether.VideoID.IVideos>>(
        ['list-videos', appliedFilters, page, rowsPerPage],
        async () => {
            let params: { [key: string]: any } = {
                'features_error|isnull': true,
                order: '-_id',
                limit: rowsPerPage,
                offset: (page - 1) * rowsPerPage,
            };

            const finalFilters: IFetchFilters = {
                status: { value: 'no_samples', filterType: 'equals' },
            };

            if (appliedFilters.titleSlug) {
                const id = await getTitleIdFromSlug(appliedFilters.titleSlug);
                finalFilters['samples_filter.related_oid'] = {
                    value: id,
                    filterType: 'equals',
                };
            }
            if (appliedFilters.season != null) {
                if (appliedFilters.season === 0)
                    params['samples_filter.related_info.season|isnull'] = true;
                else
                    finalFilters['samples_filter.related_info.season'] = {
                        value: appliedFilters.season,
                        filterType: 'equals',
                    };
            }
            if (appliedFilters.episode != null) {
                if (appliedFilters.episode === 0)
                    params['samples_filter.related_info.episode|isnull'] = true;
                else
                    finalFilters['samples_filter.related_info.episode'] = {
                        value: appliedFilters.episode,
                        filterType: 'equals',
                    };
            }

            const apiFilters = applyApiFilters(finalFilters);
            params = {
                ...params,
                ...apiFilters,
            };

            const res = await api.get<Ether.IApi<Ether.VideoID.IVideos>>(
                `${videoIdUri}/list-videos`,
                {
                    params,
                }
            );
            return res.data;
        },
        { enabled: false }
    );

    const closeVideoEdit = () => {
        setEditRelatedInfoVisible(false);
        setTimeout(() => setSelectedVideo(null), 100);
    };

    const saveRelatedInfo = (
        videoId: string,
        season: string,
        episode: string,
        relatedWith: string,
        relatedOid: string
    ) => {
        api.post(`${videoIdUri}/update-videos/${videoId}`, {
            samples_filter: {
                related_with: relatedWith,
                related_oid: relatedOid,
                related_info: {
                    season,
                    episode,
                },
            },
        }).then(() => {
            primeToastRef.current?.show({
                severity: 'success',
                summary: 'Saved related info',
                detail: 'Video season and episode saved.',
            });
            startFetch();
            closeVideoEdit();
        });
    };

    const startFetch = () => {
        refetchVideos();
    };

    useEffect(() => {
        refetchVideos();
    }, [refetchVideos]);

    useEffect(() => {
        startFetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, filterFields]);

    useEffect(() => {
        switch (videosQueryStatus) {
            case 'loading':
                setAllRequestStatus('loading');
                break;
            case 'error':
                setAllRequestStatus('error');
                break;
        }
    }, [videosQueryStatus]);

    useEffect(() => {
        if (!videosData) return;
        const validateTitles = async () => {
            const videoList: IVideoInfo[] = [];
            if (!videosData.payload) return videoList;

            const oids: { [oid: string]: string } = {};
            videosData.payload.forEach((item) => {
                if (item.samples_filter.related_with !== 'title-db') return;
                const oid = item.samples_filter.related_oid;
                oids[oid] = oid;
            });

            const titleMap: {
                [id: string]: {
                    name: string;
                    type: string;
                    slug: string;
                };
            } = {};

            const promises = Object.keys(oids).map((oid) => {
                return new Promise<void>((resolve) => {
                    getVideoTitleFromOid(oid).then((title) => {
                        titleMap[oid] = title;
                        resolve();
                    });
                });
            });
            await Promise.all(promises);

            videosData.payload.forEach((item) => {
                const metadata = {
                    _id: item._id,
                    status: item.status,
                    video_file_oid: item.video_file_oid,
                    related_with: item.samples_filter.related_with,
                    related_oid: item.samples_filter.related_oid,
                    related_info_episode:
                        item.samples_filter.related_info?.episode,
                    related_info_season:
                        item.samples_filter.related_info?.season,
                    features_file: item.features_file,
                    features_error: item.features_error,
                    features_processed_at: item.features_processed_at,
                };
                const titleOid = item.samples_filter.related_oid;
                const title = titleMap[titleOid];
                videoList.push({
                    ...metadata,
                    related_name:
                        title?.name ??
                        `${item.samples_filter.related_oid} @ ${item.samples_filter.related_with}`,
                    related_type: title?.type ?? '-',
                    related_slug: title?.slug ?? '-',
                });
            });

            return videoList;
        };
        validateTitles().then((list) => {
            setVideoIdDataList(list);
            setAllRequestStatus('success');
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videosData]);

    useEffect(() => {
        if (page === 1) startFetch();
        setPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    const renderActionsButtons = (rowData: IVideoInfo) => {
        const errorMessage = 'Must inform season and episode to tv title';
        const isError =
            rowData.related_type === 'tv' &&
            (!rowData.related_info_episode || !rowData.related_info_season);
        return (
            <div className='group-action-buttons'>
                <Button
                    className='p-button-outlined'
                    icon='pi pi-play'
                    tooltip='Watch video'
                    onClick={() => {
                        setSelectedVideo({
                            videoIdData: rowData,
                            titlesDbData: {
                                title: rowData.related_name,
                                season: rowData.related_info_season,
                                episode: rowData.related_info_episode,
                            },
                        });
                        setIsVideoPlayerVisible(true);
                    }}
                />
                {rowData.related_type !== 'movie' && (
                    <Button
                        className='p-button-outlined'
                        icon='pi pi-pencil'
                        tooltip='Update related info'
                        onClick={() => {
                            setSelectedVideo({
                                videoIdData: rowData,
                                titlesDbData: {
                                    title: rowData.related_name,
                                    season: rowData.related_info_season,
                                    episode: rowData.related_info_episode,
                                },
                            });
                            setEditRelatedInfoVisible(true);
                        }}
                    />
                )}
                {!Object.keys(appliedFilters).some((k) => {
                    const value = appliedFilters[k as keyof Partial<Filters>];
                    return value != null && value !== '';
                }) && (
                    <Button
                            className='p-button-outlined'
                            icon='pi pi-filter'
                            disabled={isError}
                            tooltip={isError ? errorMessage : 'Filter related videos'}
                            onClick={() => {
                                const redirectFilter = {
                                    titleSlug: rowData.related_slug,
                                    season: rowData.related_info_season
                                        ? Number(rowData.related_info_season)
                                        : null,
                                    episode: rowData.related_info_episode
                                        ? Number(rowData.related_info_episode)
                                        : null,
                                };
                                setFilters(redirectFilter);
                                setAppliedFilters(redirectFilter);
                            }}
                        />
                )}
                
            </div>
        );
    };

    const EditVideoDialog: React.FC = () => {
        const [videoInfo, setVideoInfo] = useState<{
            episode: string;
            season: string;
        }>({ episode: '', season: '' });
        const [error, setError] = useState<{
            episode?: string;
            season?: string;
        } | null>(null);

        useEffect(() => {
            if (!selectedVideo) return;
            const { titlesDbData } = selectedVideo;
            setVideoInfo({
                season: titlesDbData.season ? titlesDbData.season : '',
                episode: titlesDbData.episode ? titlesDbData.episode : '',
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [selectedVideo]);

        return (
            <Dialog
                header={
                    selectedVideo?.titlesDbData.title &&
                    `${selectedVideo.titlesDbData.title}`
                }
                visible={editRelatedInfoVisible}
                onHide={closeVideoEdit}
            >
                {selectedVideo && (
                    <>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Season</td>
                                    <td>
                                        <CustomInputText
                                            value={videoInfo.season}
                                            onChange={(e) => {
                                                setVideoInfo((old) => ({
                                                    ...old,
                                                    season: e.target.value,
                                                }));
                                            }}
                                            error={error && error.season}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Episode</td>
                                    <td>
                                        <CustomInputText
                                            value={videoInfo.episode}
                                            onChange={(e) => {
                                                setVideoInfo((old) => ({
                                                    ...old,
                                                    episode: e.target.value,
                                                }));
                                            }}
                                            error={error && error.episode}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div>
                            <Button
                                label='Save'
                                style={{ marginRight: '4px' }}
                                onClick={() => {
                                    if (videoInfo.season && videoInfo.episode) {
                                        const { videoIdData } = selectedVideo;
                                        saveRelatedInfo(
                                            videoIdData._id,
                                            videoInfo.season,
                                            videoInfo.episode,
                                            videoIdData.related_with,
                                            videoIdData.related_oid
                                        );
                                    } else {
                                        const error: {
                                            season?: string;
                                            episode?: string;
                                        } = {};
                                        if (videoInfo.season === '')
                                            error.season = 'Season required';
                                        if (videoInfo.episode === '')
                                            error.episode = 'Episode required';
                                        setError(error);
                                    }
                                }}
                            />
                            <Button label='Cancel' onClick={closeVideoEdit} />
                        </div>
                    </>
                )}
            </Dialog>
        );
    };

    const handleTableFilter = (e: DataTableFilterEvent) => {
        setFilterFields(e.filters);
    };

    const handleApplyFilters = () => {
        setAppliedFilters(() => {
            const titleSlug =
                filters.titleSlug !== '' ? filters.titleSlug : undefined;
            const season = filters.season != null ? filters.season : undefined;
            const episode =
                filters.episode != null ? filters.episode : undefined;
            return {
                titleSlug,
                season,
                episode,
            };
        });
    };

    const handleClearFilters = () => {
        setAppliedFilters({});
        setFilters({
            titleSlug: '',
            season: null,
            episode: null,
        });
    };

    useEffect(() => {
        refetchVideos();
    }, [appliedFilters, refetchVideos]);

    return (
        <section>
            <ConfirmDialog />
            <Toast ref={primeToastRef} />
            <EditVideoDialog />
            {selectedVideo && (
                <TheaterPlayer
                    header={
                        selectedVideo.titlesDbData.title &&
                        getVideoInfo(
                            selectedVideo.titlesDbData.title,
                            selectedVideo?.titlesDbData.season,
                            selectedVideo?.titlesDbData.episode
                        )
                    }
                    visible={isVideoPlayerVisible}
                    onHide={() => {
                        setIsVideoPlayerVisible(false);
                        setTimeout(() => setSelectedVideo(null), 100);
                    }}
                    videoIdInfo={selectedVideo.videoIdData}
                    onSampleSave={() =>
                        onSampleConfirm(selectedVideo.videoIdData)
                    }
                    onVideoReject={() =>
                        onVideoReject(selectedVideo.videoIdData)
                    }
                />
            )}
            <Link to='/' style={{ marginRight: '8px' }}>
                <Button label='Go back' />
            </Link>
            <h1>Videos without samples</h1>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(5, 1fr)',
                    alignItems: 'end',
                    gap: '8px',
                    marginBottom: '8px',
                }}
            >
                <CustomInputText
                    label='Title slug'
                    value={filters.titleSlug}
                    onChange={(e) =>
                        setFilters((old) => ({
                            ...old,
                            titleSlug: e.target.value,
                        }))
                    }
                />
                <CustomInputNumber
                    label='Season'
                    value={filters.season}
                    onChange={(e) =>
                        setFilters((old) => ({
                            ...old,
                            season: e.value,
                        }))
                    }
                />
                <CustomInputNumber
                    label='Episode'
                    value={filters.episode}
                    onChange={(e) =>
                        setFilters((old) => ({
                            ...old,
                            episode: e.value,
                        }))
                    }
                />
                <Button onClick={handleApplyFilters} label='Apply filters' />
                <Button onClick={handleClearFilters} label='Clear filters' />
            </div>
            <NoTotalPaginator
                page={page}
                rows={rowsPerPage}
                onPageChange={(e) => {
                    setPage(e.page);
                    setRowsPerPage(e.rows);
                }}
                rowsPerPageOptions={[10, 50, 100]}
                disableNext={rowsPerPage > videoIdDataList.length}
            />
            {allRequestStatus === 'error' && (
                <h3 className='p-error'>Error loading data</h3>
            )}
            <DataTable
                lazy
                loading={allRequestStatus === 'loading'}
                value={videoIdDataList}
                filterDisplay='row'
                filters={filterFields}
                onFilter={handleTableFilter}
            >
                <Column
                    field='_id'
                    header='ID'
                    body={(rowData) => <OidBadge value={rowData._id} />}
                />
                <Column header='Actions' body={renderActionsButtons} />
                <Column field='related_name' header='Title name' />
                <Column field='related_type' header='Type' />
                <Column field='related_info_season' header='Season' />
                <Column field='related_info_episode' header='Episode' />
            </DataTable>
        </section>
    );
};

export default ValidateVideos;
