import axios from 'axios';

const instance = axios.create({
    timeout: 30000,
});

instance.interceptors.request.use(
    (req) => {
        req.headers['output-format'] = 'json';
        return req;
    },
    (err) => {
        console.error(err);
    }
);

instance.interceptors.response.use(
    (res) => {
        if (res.data.success != null) {
            if (res.data.success) return res;
            throw new Error(res.data.error ?? 'The server returned an error');
        }
        return res;
    },
    (err) => {
        if (err.response) {
            if (typeof err.response?.data === 'string')
                throw new Error(err.response.data);
            throw new Error('The server returned an error');
        } else {
            if (axios.isCancel(err)) throw err;
            const error = new Error('Unhandled app error');
            if (err.request) {
                error.message = 'No response from API';
            }
            throw error;
        }
    }
);

export default instance;
