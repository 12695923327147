import Routing from 'routes/Routing';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 3,
            refetchOnWindowFocus: false,
            cacheTime: 1000 * 60 * 15,
        },
    },
});

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <Routing />
        </QueryClientProvider>
    );
}

export default App;
