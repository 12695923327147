import { Link } from 'react-router-dom';
import './style.css';

const Logo = () => {
    return (
        <div className='logo'>
            <span className='t1'>&AElig;ther</span>
            <span className='t2'>VideoID</span>
        </div>
    );
};

export const RedirectableLogo = () => {
    return (
        <Link to='/' className='logo'>
            <span className='t1'>&AElig;ther</span>
            <span className='t2'>VideoID</span>
        </Link>
    );
};

export default Logo;
