import { Outlet } from 'react-router-dom';

import Navbar from 'components/Page/Navbar';
import PageWrap from 'components/Page/PageWrap';
import ContentWrap from 'components/Page/ContentWrap';

const Main = () => {
    return (
        <PageWrap>
            <Navbar />
            <ContentWrap>
                <Outlet />
            </ContentWrap>
        </PageWrap>
    );
};

export default Main;
