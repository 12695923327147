import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';

const OptionsSelection = () => {
    return (
        <div style={{ display: 'flex', gap: '8px' }}>
            <Link to='validate'>
                <Button label='Validate videos' />
            </Link>
            <Link to='metrics'>
                <Button label='Metrics' />
            </Link>
        </div>
    );
};

export default OptionsSelection;
