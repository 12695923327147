import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Ether } from 'types';
import api from 'services/api';
import NoTotalPaginator from 'components/Form/NoTotalPaginator';
import CustomInputText from 'components/Form/CustomInputText';
import CustomInputNumber from 'components/Form/CustomInputNumber';
import CustomDropdown from 'components/Form/CustomDropdown';

type Filters = {
    titleSlug: string;
    season: number | null;
    episode: number | null;
    status: 'pending' | 'ok' | 'validation_required' | 'rejected' |'any';
};

interface CountData {
    count: number;
    samples_filter: {
        related_oid: string;
        related_info: {
            season?: string;
            episode?: string;
        };
        related_name: string;
        related_slug: string;
        related_type: string;
    };
}

const VideoCount = () => {
    const [submitted, setSubmitted] = useState(false);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const [filters, setFilters] = useState<Filters>({
        titleSlug: '',
        season: null,
        episode: null,
        status: 'any',
    });
    const [appliedFilters, setAppliedFilters] = useState<Partial<Filters>>({});

    const videoIdUri = window.API_URL;
    const titlesDbUri = window.EXTERNAL_URI_TITLEDB;

    const getVideoTitleFromOid = async (titleOid: string) => {
        const result = await api.get<Ether.IApi<Ether.TitleDB.ITitle>>(
            `${titlesDbUri}/list-title`,
            {
                params: {
                    _id: titleOid,
                    _fields: 'name,type,slug',
                },
            }
        );
        const title = {
            slug: result.data.payload[0]?.slug,
            name: result.data.payload[0]?.name,
            type: result.data.payload[0]?.type,
        };
        return title;
    };

    const getTitleIdFromSlug = async (slug: string) => {
        const result = await api.get<Ether.IApi<Ether.TitleDB.ITitle>>(
            `${titlesDbUri}/list-title`,
            {
                params: {
                    slug: slug,
                    limit: 1,
                    _fields: '_id',
                },
            }
        );
        return result.data.payload[0] ? result.data.payload[0]._id : null;
    };

    const downloadStatsQuery = useQuery<CountData[]>(
        ['count-videos', appliedFilters],
        async () => {
            const { titleSlug, episode, season, status } = appliedFilters;

            let titleId: string | undefined = undefined;
            if (titleSlug) {
                titleId = (await getTitleIdFromSlug(titleSlug)) ?? undefined;
                if (!titleId) return [];
            }

            let finalStatus: string | undefined = undefined;
            switch (status) {
                case 'ok':
                    finalStatus = 'done';
                    break;
                case 'pending':
                    finalStatus = 'new,sent,error';
                    break;
                case 'validation_required':
                    finalStatus = 'no_samples';
                    break;
                case 'rejected':
                    finalStatus = 'rejected';
            }

            const apiResult = await api.get<
                Ether.IApi<{
                    samples_filter: {
                        related_with: string;
                        related_oid: string;
                        related_info: {
                            season?: string;
                            episode?: string;
                        };
                    };
                    count: number;
                }>
            >(`${videoIdUri}/count-videos`, {
                params: {
                    group_by: 'samples_filter',
                    'samples_filter.related_oid': titleId,
                    'samples_filter.related_info.season': season,
                    'samples_filter.related_info.episode': episode,
                    'status|in': finalStatus,
                },
            });
            const oids: { [oid: string]: string } = {};
            apiResult.data.payload.forEach((item) => {
                if (item.samples_filter.related_with !== 'title-db') return;
                const oid = item.samples_filter.related_oid;
                oids[oid] = oid;
            });
            const titleMap: {
                [id: string]: {
                    name: string;
                    type: string;
                    slug: string;
                };
            } = {};

            const promises = Object.keys(oids).map((oid) => {
                return new Promise<void>((resolve) => {
                    getVideoTitleFromOid(oid).then((title) => {
                        titleMap[oid] = title;
                        resolve();
                    });
                });
            });
            await Promise.all(promises);

            return apiResult.data.payload.map((item) => {
                const title = titleMap[item.samples_filter.related_oid];
                return {
                    count: item.count,
                    samples_filter: {
                        related_info: item.samples_filter.related_info,
                        related_oid: item.samples_filter.related_oid,
                        related_slug:
                            title?.slug ??
                            `${item.samples_filter.related_oid} @ ${item.samples_filter.related_with}`,
                        related_name: title?.name ?? '-',
                        related_type: title?.type ?? '-',
                    },
                };
            });
        },
        {
            enabled: submitted,
        }
    );

    const handleApplyFilters = () => {
        setSubmitted(true);
        setAppliedFilters(() => {
            const titleSlug =
                filters.titleSlug !== '' ? filters.titleSlug : undefined;
            const season = filters.season != null ? filters.season : undefined;
            const episode =
                filters.episode != null ? filters.episode : undefined;
            const status =
                filters.status !== 'any' ? filters.status : undefined;
            return {
                titleSlug,
                season,
                episode,
                status,
            };
        });
    };

    const handleClearFilters = () => {
        setAppliedFilters({});
        setFilters({
            titleSlug: '',
            season: null,
            episode: null,
            status: 'any',
        });
    };

    const PaginatorElement = () => {
        const disableNext = downloadStatsQuery.data && downloadStatsQuery.data.length - rowsPerPage * (page - 1) <= rowsPerPage;

        return (
            <NoTotalPaginator
                page={page}
                rows={rowsPerPage}
                onPageChange={(e) => {
                    setPage(e.page);
                    setRowsPerPage(e.rows);
                }}
                rowsPerPageOptions={[10, 50, 100]}
                disableNext={disableNext}
            />
        );
    };

    return (
        <div>
            <Link to='/' style={{ marginRight: '8px' }}>
                <Button label='Go back' />
            </Link>
            <h1>Metrics</h1>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(6, 1fr)',
                    alignItems: 'end',
                    gap: '8px',
                    marginBottom: '8px',
                }}
            >
                <CustomInputText
                    label='Title slug'
                    value={filters.titleSlug}
                    onChange={(e) =>
                        setFilters((old) => ({
                            ...old,
                            titleSlug: e.target.value,
                        }))
                    }
                />
                <CustomInputNumber
                    label='Season'
                    value={filters.season}
                    onChange={(e) =>
                        setFilters((old) => ({
                            ...old,
                            season: e.value,
                        }))
                    }
                />
                <CustomInputNumber
                    label='Episode'
                    value={filters.episode}
                    onChange={(e) =>
                        setFilters((old) => ({
                            ...old,
                            episode: e.value,
                        }))
                    }
                />
                <CustomDropdown
                    style={{
                        width: '100%',
                    }}
                    label='Status'
                    options={[
                        {
                            label: 'Validation required',
                            value: 'validation_required',
                        },
                        {
                            label: 'Rejected',
                            value: 'rejected',
                        },
                        {
                            label: 'Pending',
                            value: 'pending',
                        },
                        {
                            label: 'Ok',
                            value: 'ok',
                        },
                        {
                            label: 'Any',
                            value: 'any',
                        },
                    ]}
                    value={filters.status}
                    onChange={(e) =>
                        setFilters((old) => ({
                            ...old,
                            status: e.value,
                        }))
                    }
                />
                <Button onClick={handleApplyFilters} label='Apply filters' />
                <Button onClick={handleClearFilters} label='Clear filters' />
            </div>

            {downloadStatsQuery.status === 'error' && (
                <p>
                    Error:{' '}
                    {(downloadStatsQuery.error as Error | string)?.toString()}
                </p>
            )}
            {(downloadStatsQuery.status === 'success' ||
                downloadStatsQuery.status === 'loading') && (
                <>
                    <h3>
                        Total count:{' '}
                        {downloadStatsQuery.data?.reduce(
                            (prev, curr) => prev + curr.count,
                            0
                        )}
                    </h3>
                    <PaginatorElement />
                    <DataTable
                        loading={downloadStatsQuery.status === 'loading'}
                        value={downloadStatsQuery.data}
                        rows={rowsPerPage}
                        first={(page - 1) * rowsPerPage}
                        onPage={() => {}}
                        paginator
                        paginatorTemplate={''}
                        removableSort
                    >
                        <Column
                            field='samples_filter.related_name'
                            header='Title name'
                            sortable
                        />
                        {/* <Column
                            field='samples_filter.related_slug'
                            header='Slug'
                        /> */}
                        <Column
                            field='samples_filter.related_type'
                            header='Type'
                        />
                        <Column
                            field='samples_filter.related_info.season'
                            header='Season'
                            sortable
                            body={(item) =>
                                item.samples_filter.related_info?.season ?? '-'
                            }
                        />
                        <Column
                            field='samples_filter.related_info.episode'
                            header='Episode'
                            sortable
                            body={(item) =>
                                item.samples_filter.related_info?.episode ?? '-'
                            }
                        />
                        <Column field='count' header='Count' sortable />
                        <Column
                            field='actions'
                            header='Actions'
                            body={(e: CountData) => {
                                let url = `/validate?related_slug=${e.samples_filter.related_slug}`;
                                if (e.samples_filter.related_type === 'tv') {
                                    url += `&related_info_season=${
                                        e.samples_filter.related_info.season ??
                                        '0'
                                    }`;
                                    url += `&related_info_episode=${
                                        e.samples_filter.related_info.episode ??
                                        '0'
                                    }`;
                                }

                                // const disabled =
                                //     e.samples_filter.related_type === 'tv' &&
                                //     (e.samples_filter.related_info.episode ==
                                //         null ||
                                //         e.samples_filter.related_info.season ==
                                //             null);
                                // if (disabled)
                                //     return (
                                //         <Button
                                //             label='Invalid TV title'
                                //             disabled={disabled}
                                //             tooltip={
                                //                 'TV titles must have a season and episode'
                                //             }
                                //             outlined
                                //             tooltipOptions={{
                                //                 showOnDisabled: true,
                                //                 position : 'left'
                                //             }}
                                //         />
                                //     );
                                return (
                                    <Link
                                        to={url}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <Button label='Validate' outlined />
                                    </Link>
                                );
                            }}
                        />
                    </DataTable>
                    <PaginatorElement />
                </>
            )}
        </div>
    );
};

export default VideoCount;
