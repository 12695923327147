import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Main from 'pages/Main';
import OptionsSelection from 'pages/Main/subpages/OptionsSelection';
import ValidateVideos from 'pages/Main/subpages/ValidateVideos';
import VideoCount from 'pages/Main/subpages/VideoCount';
import { AuthProvider } from 'hooks/useAuth';

const Routing = () => {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route path='/' element={<Main />}>
                        <Route index element={<OptionsSelection />} />
                        <Route path='validate' element={<ValidateVideos />} />
                        <Route path='metrics' element={<VideoCount />} />
                    </Route>
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    );
};

export default Routing;
