import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';

import api from 'services/api';

import LoadingSpinner from 'components/Misc/LoadingSpinner';
import { Button } from 'primereact/button';
import { Ether } from 'types';
import { Tooltip } from 'primereact/tooltip';
import { IVideoInfo } from '../..';

import { Player, Video, DefaultUi } from '@vime/react';

import './style.css';
import '@vime/core/themes/default.css';

interface ITheaterPlayer {
    header: string;
    visible: boolean;
    onHide(): void;
    videoIdInfo: IVideoInfo;
    onSampleSave(): void;
    onVideoReject(): void;
}

const TheaterPlayer: React.FC<ITheaterPlayer> = ({
    header,
    visible,
    onHide,
    videoIdInfo,
    onSampleSave,
    onVideoReject,
}) => {
    const filesUri = window.EXTERNAL_URI_FILE;
    const titlesDbUri = window.EXTERNAL_URI_TITLEDB;
    const imdbUri = 'https://www.imdb.com/title/';

    const [synopsisStatus, setSynopsisStatus] = useState<string>('loading');
    const [posterStatus, setPosterStatus] = useState<string>('loading');
    const [titlesDbInfo, setTitlesDbInfo] = useState<Ether.TitleDB.ITitle>();
    const [posterFile, setPosterFile] = useState<Blob | null>(null);

    useEffect(() => {
        setSynopsisStatus('loading');
        setPosterStatus('loading');
        api.get<Ether.IApi<Ether.TitleDB.ITitle>>(`${titlesDbUri}/list-title`, {
            params: {
                _id: videoIdInfo.related_oid,
            },
        })
            .then((result) => {
                setTitlesDbInfo(result.data.payload[0]);
                setSynopsisStatus('success');
                return api.get<Ether.IApi<Ether.TitleDB.IAsset>>(
                    `${titlesDbUri}/list-asset`,
                    {
                        params: {
                            title_id: videoIdInfo.related_oid,
                            type: 'poster',
                            limit: 1,
                        },
                    }
                );
            })
            .then((result) => {
                if (result.data.payload[0]) {
                    const fileInfo = result.data.payload[0];
                    api.get(`${filesUri}/download/${fileInfo.file_oid}`, {
                        responseType: 'arraybuffer',
                    })
                        .then((result) => {
                            const { data } = result;
                            const blob = new Blob([data]);
                            setPosterFile(blob);
                            setPosterStatus('success');
                        })
                        .catch(() => {
                            setPosterStatus('error');
                        });
                } else {
                    setPosterFile(null);
                }
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoIdInfo.related_oid]);

    return (
        <Dialog
            header={header}
            visible={visible}
            onHide={onHide}
            draggable={false}
            className='dialog-wrap'
        >
            <Tooltip target='.disabled-button-save-sample' />
            <div className='section-group'>
                <div className='video'>
                    <Player id='video-player'>
                        <Video>
                            <source
                                type='video/mp4'
                                src={`${filesUri}/download/${videoIdInfo.video_file_oid}`}
                            />
                        </Video>
                        <DefaultUi />
                    </Player>
                    {/* <video id='video-player' controls autoPlay>
                        <source
                            src={`${filesUri}/download/${videoIdInfo.video_file_oid}`}
                        />
                    </video> */}
                    {videoIdInfo.related_type === 'tv' &&
                    (!videoIdInfo.related_info_episode ||
                        !videoIdInfo.related_info_season) ? (
                            <span
                                className='disabled-button-save-sample'
                                data-pr-tooltip={
                                    'Must inform season and episode to tv title'
                                }
                            >
                                <div className='button-group'>
                                    <Button
                                        label='Accept match and save as sample'
                                        className='p-button-success'
                                        icon='pi pi-thumbs-up'
                                        disabled={true}
                                    />
                                    <Button
                                        label='Reject match and ignore video'
                                        className='p-button-danger'
                                        icon='pi pi-thumbs-down'
                                        disabled={true}
                                    />
                                </div>
                            </span>
                        ) : (
                            <div className='button-group'>
                                <Button
                                    label='Accept match and save as sample'
                                    className='p-button-success'
                                    icon='pi pi-thumbs-up'
                                    onClick={onSampleSave}
                                />
                                <Button
                                    label='Reject match and ignore video'
                                    className='p-button-danger'
                                    icon='pi pi-thumbs-down'
                                    onClick={onVideoReject}
                                />
                            </div>
                        )}
                </div>
                <div className='info'>
                    {synopsisStatus === 'loading' ? (
                        <LoadingSpinner />
                    ) : (
                        synopsisStatus === 'success' && (
                            <>
                                <div>
                                    <a
                                        target='_blank'
                                        href={`${imdbUri}${titlesDbInfo?.imdb_id}`}
                                        rel='noreferrer'
                                    >
                                        <span style={{ fontWeight: 'bold' }}>
                                            IMBD:{' '}
                                        </span>
                                        {`${imdbUri}${titlesDbInfo?.imdb_id}`}
                                    </a>
                                </div>
                                <div>
                                    {(() => {
                                        const synopsis =
                                            titlesDbInfo?.synopsis.find(
                                                (item) => item.value !== ''
                                            );
                                        return (
                                            <p>
                                                <span
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Synopsis (
                                                    {synopsis?.language}):
                                                </span>{' '}
                                                {synopsis?.value}
                                            </p>
                                        );
                                    })()}
                                </div>
                                {titlesDbInfo &&
                                    titlesDbInfo.production_companies.length >
                                        0 && (
                                    <div>
                                        <span
                                            style={{ fontWeight: 'bold' }}
                                        >
                                                Production companies:
                                        </span>{' '}
                                        {titlesDbInfo.production_companies.join(
                                            ', '
                                        )}
                                    </div>
                                )}
                            </>
                        )
                    )}
                    {posterStatus === 'loading' ? (
                        <LoadingSpinner />
                    ) : posterStatus === 'success' ? (
                        posterFile && (
                            <div>
                                <img src={URL.createObjectURL(posterFile)} alt='Poster' />
                            </div>
                        )
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </Dialog>
    );
};

export default TheaterPlayer;
